import "./shop.css";
import Layout from "../layouts/Layout";

import React, { useEffect, useRef } from "react";

export default function Shop() {
	const shopRef = useRef();

	useEffect(() => {
		window.spread_shop_config = {
			shopName: "faintlines",
			locale: "us_US",
			prefix: "https://faintlines.myspreadshop.com",
			baseId: "spreadshop",
		};

		const script = document.createElement("script");
		script.src =
			"https://faintlines.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js";
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => modifyShop(shopRef.current), 500);
		return () => clearInterval(interval);
	});

	return (
		<Layout title="Shop | FaintLines">
			<h1>{"Shop"}</h1>
			<div id="spreadshop" className="spreadshop" ref={shopRef}>
				<a href="https://faintlines.myspreadshop.com">{"faintlines"}</a>
			</div>
		</Layout>
	);
}

function modifyShop(shop) {
	shop.querySelectorAll(".sprd-product-list-item__title:not(.mod)").forEach(
		(elem) => {
			elem.classList.add("mod");
			const [name, type] = elem.textContent.split(" - ");
			elem.innerHTML = `<span class="name">${name}</span><span class="type">${type}</span>`;
		}
	);
}
